import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

import { rhythm, scale } from '../utils/typography'

import styles from './layout.module.scss'

library.add(fab)

const Layout = ({ data, children }) => (
  <div
    style={{
      marginLeft: `auto`,
      marginRight: `auto`,
      maxWidth: rhythm(24),
      padding: `0 ${rhythm(3 / 4)} ${rhythm(1.5)}`,
    }}
  >
    <header className={styles.header}>
      <Link className={styles.home} to={`/`}>
        <h1
          style={{
            ...scale(1.5),
            color: '#e4002b'
          }}
        >
          {data.site.siteMetadata.title}
        </h1>
      </Link>
      <nav>
        <ul>
          <li><Link to="/">Categories</Link></li>
          <li><Link to="/">Contact</Link></li>
        </ul>
      </nav>
    </header>
    <main>{children}</main>
    <footer className={styles.footer}>
      <div className={styles.social}>
        <a href={data.site.siteMetadata.social.github} >
          <FontAwesomeIcon icon={['fab', 'github-alt']} />
        </a>
        <a href={data.site.siteMetadata.social.twitter} >
          <FontAwesomeIcon icon={['fab', 'twitter']} />
        </a>
      </div>
      © {new Date().getFullYear()} {data.site.siteMetadata.author} All Rights Reserved.
    </footer>
  </div>
)

export default props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
            author
            social {
              github
              twitter
            }
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
)
